import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Player } from 'video-react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Sidebar from '../../components/universities/sidebar';
import AnkerIntro from '../../components/home/anker-intro';
import * as styles from './Highlights.module.scss';

class Highlights extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const temp = {
      video: {
        banner: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/production/%E5%87%BA%E6%B5%B7%E5%90%A7%E8%88%B0%E9%95%BF%E6%97%A0%E9%81%AE%E5%B9%85%E5%B9%85%E7%89%88%E6%9C%AC%E5%8E%8B%E7%BC%A9.mp4',
        poster: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/0805_1_8b7af95d26.jpeg',
      },
      section1: [
        {
          title: '平台亮点',
          subTitle: '3个年销售过15亿智能硬件品牌创造者',
          intro: {
            content: [
              "<p class='subPoint'>开创了15个营收过亿<br/>智能硬件产品线</p>",
              "<p class='subPoint'>积累了20个专业拔群<br/>品牌中台资源池</p>",
              "<p class='subPoint'>覆盖146+国家和地区<br/>超过1亿忠实用户</p>",
            ],
            image: [
              'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_highlight_1_9e9b7d5ee1.png',
              'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_highlight_2_4378cfa88e.png',
              'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_highlight_3_aabf119e14.png',
            ],
          },
        },
        {
          title: '资源亮点',
          subTitle: '提供全价值链的赋能体系',
          intro: {
            content: [
              "<p class='subPoint'>产品线负责人领军的<br/>最小化业务单元创新组织模式</p>",
              "<p class='subPoint'>从设计、品牌、供应链<br/>到全球渠道流程化组织</p>",
              "<p class='subPoint'>数据驱动的商业操作系统</p>",
            ],
            image: [
              'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_highlight_4_0b5424f4d6.png',
              'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_highlight_5_8f54de9176.png',
              'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_highlight_6_06d6acbfee.png',
            ],
          },
        },
      ],
      section2: [
        {
          text: '项目简介',
          url: '/project/introduction',
        },
        {
          text: '项目亮点',
          url: '/project/highlights',
        },
        {
          text: '职位信息',
          url: '/project/job',
        },
        {
          text: '创业者案例',
          url: '/project/entrepreneurcase',
        },
      ],
      seo: {
        title: '安克创新招聘.安克创新校园招聘.加入安克创新',
        keywords: ['安克创新招聘', '长沙上市公司招聘', '跨境电商公司招聘', '长沙互联网公司招聘', 'Anker招聘', '智能硬件出海公司招聘'],
        description: '作为标杆出海企业，安克创新致力于在全球市场塑造中国消费电子品牌，通过不断创新，将富有科技魅力的领先产品带向全球消费者，弘扬中国智造之美。安克创新现成功打造了3个年营收过15亿的出海品牌，拥有全球100多个国家与地区的7500万用户。',
      },
    };
    const {
      data, location = {},
    } = this.props;
    const copywriting = data.contentfulCopywriting;

    const { pathname } = location;
    return (
      <div className={`${styles.home} highlightsWrap`}>
        <div className={`${styles.videoWrap} video-wrapper`}>
          <Player src={temp.video.banner} poster={temp.video.poster} playsInline />
          <div className="video-text-wrapper">
          </div>
        </div>
        {/* <div className="video-wrapper">
          <video width="100%" height="600px" style={{objectFit:'fill',height:"600px"}} muted="muted" src={temp.banner}>
          </video>
          <Player height="600px" src={temp.banner} />
        </div>  */}
        <Layout {...this.props} hideSearch={true} copywriting={copywriting}>
          <SEO title={temp.seo.title} keywords={temp.seo.keywords} description={temp.seo.description} />
          <div className={'projectIntroduction'}>
            <Sidebar pathname={pathname} temp={temp.section2}></Sidebar>
            <div className="userIntroContainer">
              {
                temp.section1.map((item, i) => (
                  <div className="highlights" key={`${item}${i}`}>
                    <p className="highlightsTitle">{item.title}</p>
                    <p className="subTitle">{item.subTitle}</p>
                    <AnkerIntro temp={item.intro}></AnkerIntro>
                  </div>
                ))
              }

            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Highlights;

export const query = graphql`
query highlightQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
  }
} 
`;
